import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store";
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { requireAuth: true,  },
    component: HomeView
  },
  {
    path: '/pos',
    name: 'pos',
    meta: { requireAuth: true, requirecashier: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/PosView.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: { inecesaria: true},
    component: () => import(/* webpackChunkName: "about" */ '../components/LoginView.vue')
  },
  {
    path: '/newcorte',
    name: 'newcorte',
    meta: { requireAuth: true,  requirecashier: true },
    component: () => import(/* webpackChunkName: "about" */ '../components/NewCorte.vue')
  },
  {
    path: '/cerrarcorte',
    name: 'CerrarCorte',
    meta: { requireAuth: true,  requirecashier: true  },
    component: () => import(/* webpackChunkName: "about" */ '../components/CerrarCorte.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    meta: { requireAuth: true, requireadmin: true  },
    component: () => import(/* webpackChunkName: "about" */ '../views/SettingsView.vue')
  },
  {
    path: '/products',
    name: 'products',
    meta: { requireAuth: true,  requirecashier: true  },
    component: () => import(/* webpackChunkName: "about" */ '../components/ProductsView.vue')
  },
  {
    path: '/proveedores',
    name: 'proveedores',
    meta: { requireAuth: true,  requirecashier: true  },
    component: () => import(/* webpackChunkName: "about" */ '../components/ProveedoresView.vue')
  },
  {
    path: '/ventas',
    name: 'ventas',
    meta: { requireAuth: true,  requirecashier: true  },
    component: () => import(/* webpackChunkName: "about" */ '../components/VentasView.vue')
  },
  {
    path: '/customers',
    name: 'customers',
    meta: { requireAuth: true,  requirecashier: true  },
    component: () => import(/* webpackChunkName: "about" */ '../components/CustomerView.vue')
  },
  {
    path: '/users',
    name: 'users',
    meta: { requireAuth: true, requireadmin: true },
    component: () => import(/* webpackChunkName: "about" */ '../components/UsersView.vue')
  },
  {
    path: '/customerbalancecreditrange/:id/:dates',
    name: 'customerbalancecreditrange',
    meta: { requireAuth: true, requirecashier: true  },
    component: () => import(/* webpackChunkName: "about" */ '../components/BalanceCustomerCreditRange.vue')
  },
  {
    path: '/customerbalancecredit/:id',
    name: 'customerbalancecredit',
    meta: { requireAuth: true, requirecashier: true   },
    component: () => import(/* webpackChunkName: "about" */ '../components/BalanceCustomerCredit.vue')
  },
  {
    path: '/reports',
    name: 'reports',
    meta: { requireAuth: true, requireadmin: true  },
    component: () => import(/* webpackChunkName: "about" */ '../components/ReportView.vue')
  },
  {
    path: '/rventasmes',
    name: 'rventasmes',
    meta: { requireAuth: true, requireadmin: true  },
    component: () => import(/* webpackChunkName: "about" */ '../components/RventasMes.vue')
  },
  {
    path: '/fglobal',
    name: 'fglobal',
    meta: { requireAuth: true, requireadmin: true  },
    component: () => import(/* webpackChunkName: "about" */ '../components/GlobalInvoicing.vue')
  },
  {
    path: '/rcortescajeros',
    name: 'rcortescajeros',
    meta: { requireAuth: true, requireadmin: true  },
    component: () => import(/* webpackChunkName: "about" */ '../components/RcortesCajeros.vue')
  },
  {
    path: '/RsaldosCredito',
    name: 'RsaldosCredito',
    meta: { requireAuth: true, requireadmin: true  },
    component: () => import(/* webpackChunkName: "about" */ '../components/ReporteSaldosCredito.vue')
  },
  {
    path: '/configfacturacion',
    name: 'configfacturacion',
    meta: { requireAuth: true, requireadmin: true  },
    component: () => import(/* webpackChunkName: "about" */ '../components/ConfigFacturacion.vue')
  },
  {
    path: '/invoices',
    name: 'invoices',
    meta: { requireAuth: true,  },
    component: () => import(/* webpackChunkName: "about" */ '../components/InvoicesView.vue')
  },
  {
    path: '/facturacion/:id',
    name: 'facturacion',
    meta: { requireAuth: false,  },
    component: () => import(/* webpackChunkName: "about" */ '../components/FacturacionView.vue')
  },
  {
    path: '/facturacionmasiva',
    name: 'facturacionmasiva',
    meta: { requireAuth: false,  },
    component: () => import(/* webpackChunkName: "about" */ '../components/FacturacionMasiva.vue')
  },
  {
    path: '/misfacturas/:sucursal/:rfc',
    name: 'misfacturas',
    meta: {  },
    component: () => import(/* webpackChunkName: "about" */ '../components/MisFacturas.vue')
  },
  {
    path: '/entradamercancia',
    name: 'entrada',
    meta: { requireAuth: true,  },
    component: () => import(/* webpackChunkName: "about" */ '../components/EntradaProductos.vue')
  },
  {
    path: '/checador',
    name: 'checador',
    meta: { requireAuth: true,  },
    component: () => import(/* webpackChunkName: "about" */ '../components/ChecadorView.vue')
  },
  {
    path: '/salidamercancia',
    name: 'salida',
    meta: { requireAuth: true,  },
    component: () => import(/* webpackChunkName: "about" */ '../components/SalidaProductos.vue')
  },
  {
    path: '/almacen',
    name: 'almacen',
    meta: { requireAuth: true, requirestorer:true  },
    component: () => import(/* webpackChunkName: "about" */ '../views/StorageView.vue')
  },
  {
    path: '/entradaxml',
    name: 'entradaxml',
    meta: { requireAuth: true, requirestorer:true  },
    component: () => import(/* webpackChunkName: "about" */ '../components/EntradaProductosXML.vue')
  },
  {
    path: '/receptionTruper',
    name: 'receptionTruper',
    meta: { requireAuth: false},
    component: () => import(/* webpackChunkName: "about" */ '../components/ReceptionTruper.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const rutaProtegida = to.matched.some((record) => record.meta.requireAuth);
  const rutaInecesaria = to.matched.some((record) => record.meta.inecesaria);

  const rutadmin = to.matched.some(
    (recordadmin) => recordadmin.meta.requireadmin
  );

  const rutastorer = to.matched.some(
    (recordstorer) => recordstorer.meta.requirestorer
  );

  const rutacashier = to.matched.some(
    (recordcashier) => recordcashier.meta.requirecashier
  );
  //console.log(store.state.usuarioDB.data.role);
  if (rutaProtegida && store.state.token === "") {
    next({ name: "login" });
  } else {
    next();
  }
  if (rutaInecesaria && store.state.token != "") {
    next({ name: "home" });
  } else {
    next();
  }

  if (rutadmin && store.state.usuarioDB.data.role == "CASHIER") {
    //console.log(store.state.token);

    next({ name: "home" });
  } else if (rutadmin && store.state.usuarioDB.data.role == "ADMIN") {
    next();
  }else if (rutadmin && store.state.usuarioDB.data.role == "STORER") {
    next({ name: "home" });
  }

  if (rutastorer && store.state.usuarioDB.data.role == "CASHIER") {
    //console.log(store.state.token);

    next({ name: "home" });
  } else if (rutastorer && store.state.usuarioDB.data.role == "ADMIN") {
    next();
  }else if (rutastorer && store.state.usuarioDB.data.role == "STORER") {
    next();
  }

  if (rutacashier && store.state.usuarioDB.data.role == "CASHIER") {
    //console.log(store.state.token);

    next();
  } else if (rutacashier && store.state.usuarioDB.data.role == "ADMIN") {
    next();
  }else if (rutacashier && store.state.usuarioDB.data.role == "STORER") {
    next({ name: "home" });
  }


});

export default router
